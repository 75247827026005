import React, { useContext } from 'react';
import styled from 'styled-components';
import { PRIMARY_SUBTITLE_COLOR, PRIMARY_TEXT_COLOR, PRIMARY_TITLE_COLOR } from '../../constants';
import { Col, Row, Container } from 'reactstrap';
import { Logo } from '../shared';
import { NavigationContext } from '../layout/layout';
import Link from '../shared/link';
import { globalHistory } from '@reach/router';

export const NavbarContext = React.createContext(false);

const NavigationContainer = styled.nav`
    width: 100%;
    flex-grow: 0;
   /*  box-shadow: ${props => (props.showShadow && props.theme !== 'dark' ? 'inset 0 210px 40px -165px rgba(0,0,0,0.35)' : null)}; */
    background-color: ${props => props.bgColor} /* ${props => (props.theme === 'dark' ? PRIMARY_TEXT_COLOR : null)} */;
    position: ${props => (props.relative ? 'relative' : 'absolute')};
    z-index: 20;
    padding: 30px 0;
    transition: box-shadow linear 200ms;

    .reversed {
        display: flex;
        flex-direction: row-reverse;
    }
`;

const Hamburger = styled(props => (
    <div {...props}>
        <div />
        <div />
        <div />
    </div>
))`
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
        width: 25px;
        height: 3px;
        background-color: ${props => (props.color ? props.color : PRIMARY_TEXT_COLOR)};
        margin: 2px 0;
        border-radius: 2px;
    }

    @media (max-width: 768px) {
        div {
            margin: 1.5px 0;
        }
    }
`;

const Navigation = styled.div`
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    a {
        font-family: Futura, sans-serif;
        display: inline-block;
        white-space: nowrap;
        color: ${props => props.color};
        transition: color linear 200ms;
        font-weight: 400;
        font-size: 18px;
        letter-spacing: -0.1px;
    }

    a:hover {
        text-decoration: none;
        color: ${PRIMARY_SUBTITLE_COLOR};
    }
`;
const NavigationItem = ({ to, children, ...props }) => (
    <Link {...props} to={to}>
        <span className="d-inline-block h-100 align-middle" />
        <span className="align-middle">{children}</span>
    </Link>
);

export const Navbar = ({ theme, showShadow, bgColor, backToTop, setCanScroll, canScroll }) => {
    const { enabled, setEnabled } = useContext(NavigationContext);
    const isLocationCareers = globalHistory.location.pathname === '/careers';

    return (
        <NavigationContainer theme={theme} showShadow={showShadow} bgColor={bgColor} relative={isLocationCareers}>
            <Container>
                <Row>
                    <Col xs={6} md={3} xl={2}>
                        <Link to={'/'} onClick={backToTop}>
                            <Logo fill={theme === 'dark' ? PRIMARY_TITLE_COLOR : PRIMARY_TEXT_COLOR} onClick={backToTop} />
                        </Link>
                    </Col>
                    <Col xs={{ size: 2, offset: 4 }} md={{ size: 6, offset: 3 }} className="reversed">
                        <Hamburger
                            className="d-lg-none"
                            color={theme === 'dark' ? PRIMARY_TITLE_COLOR : PRIMARY_TEXT_COLOR}
                            onClick={() => {
                                setEnabled(!enabled);
                                setCanScroll(!canScroll);
                            }}
                        />
                        <Navigation className="d-none d-lg-flex" color={theme === 'dark' ? PRIMARY_TITLE_COLOR : PRIMARY_TEXT_COLOR}>
                            <NavigationItem to={'/about'}>About</NavigationItem>
                            <NavigationItem to={'/services'}>Services</NavigationItem>
                            <NavigationItem to={'/case-studies'}>Case studies</NavigationItem>
                            <NavigationItem to={'/careers'}>Careers</NavigationItem>
                            <NavigationItem to={'/contact'}>Contact</NavigationItem>
                        </Navigation>
                    </Col>
                </Row>
            </Container>
        </NavigationContainer>
    );
};
