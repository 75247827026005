import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import 'bootstrap/dist/css/bootstrap.min.css';
import './layout.css';
import './fontello.css';

import { Footer } from '../footer';
import { Navbar } from '../navbar/navbar';

import styled from 'styled-components';
import { PRIMARY_TEXT_COLOR, TITLE_FONT, SECONDARY_BG_COLOR } from '../../constants';
import { CookiesToast } from '../shared';
import Link from '../shared/link';
import { Container } from 'reactstrap';
import PageTransition from 'gatsby-plugin-page-transitions';
import { globalHistory } from '@reach/router';

const Wrapper = styled.div`
    position: relative;
`;

const MobileNavigation = ({ className, canScroll, setCanScroll }) => {
    const MobileNavigationContainer = styled.div`
        height: 100%;
        width: 100%;
        overflow: scroll;
        position: relative;
        background-color: ${SECONDARY_BG_COLOR};
    `;

    const ItemContainer = styled(Container)`
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        flex-grow: 1;
        padding-top: 15px;
        min-height: 80%;
    `;

    const Item = styled(Link)`
        font-family: ${TITLE_FONT};
        font-size: 22px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: -0.2px;
        color: ${PRIMARY_TEXT_COLOR};
        text-decoration: none;
        position: relative;
        text-align: left;
        margin-left: 2em;
        margin-right: 2em;
        padding-bottom: 0.5em;
        padding-top: 0.5em;
        border-bottom: 2px ${PRIMARY_TEXT_COLOR} solid;

        :hover {
            text-decoration: none;
        }

        &:last-of-type {
            border-bottom: none;
        }
    `;

    const Close = styled.div`
        text-align: right;
        font-size: 20px;
        font-weight: bold;
        margin-left: auto;
    `;

    const { enabled, setEnabled } = useContext(NavigationContext);
    const location = globalHistory.location.pathname;

    const closeNavbar = () => {
        setEnabled(!enabled);
        setCanScroll(!canScroll);
    };

    const closeNavbarAndRedirect = () => {
        setTimeout(() => {
            setCanScroll(!canScroll);
            setEnabled(!enabled);
        }, 500);
    };

    return (
        <MobileNavigationContainer className={className}>
            <ItemContainer className="animated-mobile-navbar">
                <Close
                    className="closeMenu"
                    onClick={() => {
                        closeNavbar();
                    }}
                >
                    <div id="animatedHamburgerMenu">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </Close>

                <Item
                    to={'/'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Home
                </Item>

                <Item
                    to={'/about'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/about' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    About
                </Item>

                <Item
                    to={'/services'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/services' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Services
                </Item>

                <Item
                    to={'/case-studies'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/case-studies' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Case studies
                </Item>

                <Item
                    to={'/careers'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/careers' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Careers
                </Item>

                <Item
                    to={'/contact'}
                    activeClassName={'active'}
                    onClick={() => {
                        location === '/contact' ? closeNavbar() : closeNavbarAndRedirect();
                    }}
                >
                    Contact
                </Item>
            </ItemContainer>
        </MobileNavigationContainer>
    );
};

const Layout = ({ children, theme, showShadow, withNavigation, bgColor, backToTop, setCanScroll, canScroll, hideLogo }) => {
    const [enabled, setEnabled] = useState(false);

    return (
        <NavigationContext.Provider value={{ enabled, setEnabled }}>
            {enabled ? (
                <MobileNavigation setCanScroll={setCanScroll} canScroll={canScroll} />
            ) : withNavigation ? (
                <Wrapper>
                    <Navbar
                        theme={theme}
                        showShadow={showShadow}
                        bgColor={bgColor}
                        backToTop={backToTop}
                        setCanScroll={setCanScroll}
                        canScroll={canScroll}
                    />
                    <PageTransition>{children}</PageTransition>
                    <Footer hideLogo={hideLogo} />
                </Wrapper>
            ) : (
                children
            )}
            <CookiesToast />
        </NavigationContext.Provider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    theme: PropTypes.string,
    withNavigation: PropTypes.bool,
};

Layout.defaultProps = {
    theme: 'light',
    withNavigation: true,
};

export const NavigationContext = React.createContext(false);
export default Layout;
